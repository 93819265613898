@import url('https://fonts.googleapis.com/css2?family=M+PLUS+Rounded+1c:wght@100;300;400;500;700;800;900&display=swap');

body {
  margin: 0;
  /* min-width: 1096px; */
}

.header-wrap {
  max-width: 1400px;
  height: calc(100vh - 0px);
  padding: 0px 80px;
  max-height: calc(100vh - 0px);
  position: relative;
  margin: auto;
}

.header-box {
  position: relative;
  /* background-color: #E1DEDB; */
  overflow: hidden;
}

.header-menu {
  height: 120px;
  display: flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  padding: 20px 0 40px 0;
}



.logo {
  max-height: 70px;
}

.menu {
  margin-left: auto;
  display: flex;
  flex-direction: row;
  column-gap: 20px;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 2px 12px 0px;   
  padding: 10px 30px;
  border-radius: 30px;
  margin-left: auto;
  margin-right: auto;
}

.menu > a {
  font-family: 'M PLUS Rounded 1c' !important ;
  text-decoration: none;
  font-size: 18px;
  font-weight: 500 !important;
}

.menu > a:hover {
  text-decoration: underline;
  cursor: pointer;
}


.menu > span {
  font-family: 'M PLUS Rounded 1c' !important ;
  font-weight: 400;
  font-size: 26px;
}

.active {

  border-radius: 30px !important;
  padding: 4px 20px;
  margin: -5px 0 -5px -24px;
  color: white;
}


.header {
  display: flex;
  flex-wrap: wrap;
  max-height: calc(100vh - 200px);
  height: calc(100vh - 200px);

}

.header > div {
  flex: 1 0 50%;
}

.header-img {
  /* width: 100%;  */
  object-fit: cover;
  /* max-width: 100%; */
  min-height: 500px;
  width:  100%;
  max-height: 610px;
  border-radius: 20px;
}

.header-left {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: start;
}

.header-text1 {
  font-family: 'M PLUS Rounded 1c' !important ;
  font-weight: 800;
  font-size: 40px;
  line-height: 42px;
  padding-right: 40px;
 }

 .header-text2 {
  padding-right: 40px;
  font-weight: 400;
  font-size: 16px;
  
 }

 .header-button {
  display: inline-block !important;
 }


 .header-banner {
  position: absolute;
  background-color: #222222;
  color: white;
  height: 60px;
  bottom: 80px;
  left: -100px;
  right: -100px;

  font-family: 'M PLUS Rounded 1c' !important ;
  font-weight: 500;
  font-size: 26px;
  border: 2px solid #222222;
   -webkit-transform: rotate(-4deg);  
   align-items: center;
   display: flex;
   column-gap: 10px;
   text-wrap: nowrap !important;
   align-items: center;
   align-content: center;
   justify-content: center;


   -webkit-transition: all 0.5s ease-in-out;
   -moz-transition: all 0.5s ease-in-out;
   -o-transition: all 0.5s ease-in-out;
   transition: all 0.5s ease-in-out;
 }


 .content {
  max-width: 1400px;
  padding: 0px 0px;
  margin: auto;
}

 .center {
  text-align: center;
 }

 .clients {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  column-gap: 10px;
 }

h1,h2,h3,h4,h5 {
  font-family: 'M PLUS Rounded 1c' !important ;
}

 .clients > img {
  max-height: 90px;
}


.social {
  display: flex;
  flex-direction: row;
}


.vvles {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.vvles > img {
  max-height: 80px;
}




.case-tag {
  display: flex;
  flex-direction: row;
  column-gap: 6px;
  margin-left: auto;
  /* justify-content: flex-end;
  align-items: flex-end;
  justify-items:flex-end; */
  text-wrap: nowrap;
}


.slide-point {
  width: 20px;
  height: 20px;
  border-radius: 11px;
  background-color: #7D7D7D52;
  border: 2px solid white;
  cursor: pointer;
}

.selected {

  background-color: #0081CF;
}                     
 
.case-details {
  max-width: 1200px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: calc(100% - 40px);
}

.case-info {
  
  font-size: 18px !important;
  font-weight: 500 !important;
  flex-direction: row !important;
}


.case-img {
  display: flex;
  border-radius: 20px;
  overflow: hidden;
  margin: auto !important;
  box-shadow: rgba(100, 100, 111, 0.4) 0px 2px 4px 0px;
 position: relative;
 max-width: 1200px;
}

.case-nav-left {
  position: absolute;
  left: -10px;
  height: 70px;
  display: flex;
  top: calc(50% - 35px);
  /* z-index: 9200; */
  background-color: #00000015 !important;
  /* visibility: hidden; */
}

.case-nav-right {
  position: absolute;
  /* visibility: hidden; */
  

  right: -10px;
  height: 70px;
  display: flex;
  top: calc(50% - 35px);
  z-index: 9200;
  background-color: #00000015 !important;

}

.case-open {
  display: flex;
  column-gap: 20px;
  padding: 0 0 20px 0;
}

.case-img > img {
  max-width: 100%;
  width: 100%;
  opacity: 1;
  -webkit-transition: opacity  .3s ease-in-out;
  -moz-transition: opacity  .3s ease-in-out;
  transition: opacity  .3s ease-in-out;
}


.case-wrap {
 width: calc(50% - 20px);
 height: 600px;
  padding: 0;
  position: relative;
  border-radius: 10px;
  background-color: #CDCDCD;
  /* box-shadow: rgba(100, 100, 111, 0.4) 0px 2px 12px 0px; */
     /* background-color: blue;  */
     overflow:hidden;
}

.case-wrap > img {
  height: 100%;
  width: 100%;
  /* min-width: 70% !important; */
  object-fit:  contain;
  object-position: top center;

}

.works-img-main {
  /* border: 2px solid #D65B30; */
  /* width: 70%; */
}

.works-img-secondary {
  /* -webkit-filter: grayscale(100%);  */
  opacity: 0.6;
  box-shadow: none !important;
}


.works-pag {
  display: flex;
  width: 100%;
  column-gap: 5px;
  justify-content: center;
}

.works-pag > * {
  cursor: pointer;
  padding: 10px 0 20px 0;
}

.work-tech-tag {
  position: absolute !important;
 
  bottom: 20px !important;
  right: 20px !important;
}

.work-img-descr {
  
  position: absolute;
  bottom: 0px;
  left: 0px;
  display: flex;
  flex-direction: row;
  right: 0px;
  height: auto;
  z-index: 1000;
   background-color:#FFFFFFD0; 
  padding: 0px 20px;
  font-family: 'Roboto';
  font-weight: 500;
  justify-items: start;
  justify-content: left;
  align-self:flex-start;
  border-radius: 0 0 10px 10px;
  column-gap: 10px;
} 

.work-tags {
  /* flex-wrap: nowrap !important; */
  text-wrap: nowrap !important;
 flex-wrap: wrap;
  gap: 5px;
  
  display: flex;
  margin-left: auto;
  
  justify-content:   stretch;
  padding: 10px;
 max-width: 300px;
}


.tech {
  
  margin: auto;
  display: flex;
  max-width: 500px;
  position: relative;
  /* border-radius: 20px; */
  /* overflow: hidden; */
}

.tech > img {
  max-width: 100%;
  margin: auto;
  border-radius: 50%;
}

.tech-it {
  position: absolute;
  font-size: 42px;
  padding: 0px 20px 5px 20px;
  font-weight: 500;
  border-radius: 8px;
  border: 4px solid white;

  -webkit-animation:all 1s linear infinite;
  -moz-animation:all 1s linear infinite;
  animation:all 1s linear infinite;
}

.t-react {
  background-color: #B5E71B;
  color: #2D4000;
  left: calc(50% - 100px); 
  top: 60px;
  -webkit-transform: rotate(-14deg);  
}

.t-asp {
  background-color: #B975DA;
  color: #222222;
  left: calc(50% + 100px); 
  top: 60px;
  -webkit-transform: rotate(24deg);  
}

.t-kotlin {
  background-color: #E70189;
  color: #FFFFFF;
  left: calc(50% - 260px); 
  top: 50px;
  -webkit-transform: rotate(-32deg);  
}

.t-csharp {
  background-color: #603ED1;
  color: #FFFFFF;
  left: calc(50% - 260px); 
  top: 50%;
  -webkit-transform: rotate(-12deg);  
}

.case-box {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;

  
 }

 .case-sect {
  display: flex;
  flex-direction: column;
  background-color: #F2F2F2;
  padding: 0px 10% 20px 10%; 
}



 .case-all {

  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 1400px;
  margin: auto;

 }

 
 .case-im {
  
  background-color: white;
   /* border: 2px solid #828C9D93;  */
  border-radius: 10px;
  /* overflow: hidden; */
  box-shadow: rgba(100, 100, 111, 0.4) 0px 2px 4px 0px; 
  /* max-width: 800px; */
  /* flex-grow: 2; */
  position: relative;
   flex: 2   2 80%;  
    /* min-width: 100%;    */
    max-width: 900px;   
    margin: auto;
    width: 100%;
    display: flex;
    flex-direction: row;
    overflow-x: hidden;
    padding: 10px;
 }

 .case-im > img {
  
  max-width: 100%;
  
  /* pointer-events: none !important; */
  z-index: 8000;
 }


 .case-inf {
  max-width: 900px; 
  margin: 0 auto 0 auto;
  flex: 2 2 0; 

  padding: 0 0 0 20px;
  position: relative;
 }


 .case-live {
  cursor: pointer;
  /* position: absolute;
  right: 10px;
  bottom:10px; */

  margin-top: 20px;
  
  /* background-color: #00000012; */
 }


 .case-hide {
  display: none;
  position: absolute !important;
  left: 100px;
  top: 100px;
  z-index: 9000;
  width: 100px;
  height: 100px;
  /* background-color: #0081CF; */
}






.case-head {
  display: flex;
  flex-direction: row;
  gap: 10px;
  /* justify-items: flex-end;
  align-items: flex-end;
  align-content: flex-end;
  justify-items: flex-end; */
  
}

.case-head > {
  margin: 0 !important;
  padding: 0 !important;
  display: inline-block !important;
  
}

.case-head:last-child {
  
  /* float: right; */
}

.case-live {
  margin-left: auto;
}

.case-im2 {
  position: relative;
  max-width: 100%;
  display: flex;
  overflow: hidden;
}

.case-im2 > img {
  max-width: 100%;
}

.case-pag {
  position: absolute;
  display: flex;
  flex-direction: row;
  column-gap: 10px;
  justify-content: center;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 9400;
}


.request-form {
    max-width: 600px;
    margin: auto;
    padding: 0 0 40px 0;
}

.number {
  display: inline;
  background-image: linear-gradient(#ffffff00 60%, #B5E71B9D 55%);
  

}

html { scroll-behavior: smooth; }


.case-touch {
  position: absolute;
  pointer-events:    stroke;
  left: 0px;
  right: 0px;
  top: 0;
  z-index: 9800;
  bottom: 0;
  background-color: #B5E71B33;
}

.footer {
  background-color: #E5E5E5;
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  justify-items: center;
  align-items: center;
  gap:20px;
  margin: auto;
}

.footer-nav {
  display: flex;
  flex-direction: row;
  column-gap: 10px;
}

h5 {
  display: block;
  margin: 0 auto 0 auto;
  opacity: 0.5;
  color: #222222 !important;
  max-width: 600px;
}

.footer-inf {
  display: block;
  margin: 0 10%;
  text-align: center;
}

.hearts {
  color: red;
}